import { useCallback, useMemo } from 'react';
import { IconCalculator, IconClick } from '@tabler/icons-react';
import first from 'lodash/first';
import get from 'lodash/get';
import last from 'lodash/last';
import shortid from 'shortid';
import {
  Dropdown,
  SelectInput,
  SwitchButton,
  Tooltip,
} from '@noloco/components';
import { CARD, cardStyles } from '@noloco/components/src/constants/cardStyles';
import {
  ROADMAP,
  SATELLITE,
  TERRAIN,
} from '@noloco/components/src/constants/mapTypes';
import { DARK } from '@noloco/components/src/constants/surface';
import { LG, MD, SM, XL } from '@noloco/components/src/constants/tShirtSizes';
import { Views } from '@noloco/react-big-calendar';
import { DataTypeValue } from '@noloco/ui/src/components/canvas/DataTypeInput';
import StringPropEditor from '@noloco/ui/src/components/canvas/StringPropEditor';
import RecordTitleEditor from '@noloco/ui/src/components/editor/customerEditors/RecordTitleEditor';
import SizeEditor from '@noloco/ui/src/components/editor/customerEditors/SizeEditor';
import ViewLinkEditor from '@noloco/ui/src/components/editor/customerEditors/ViewLinkEditor';
import ViewRecordStyleEditor from '@noloco/ui/src/components/editor/customerEditors/ViewRecordStyleEditor';
import { UpdatePropertyCallback } from '@noloco/ui/src/utils/hooks/projectHooks';
import { NUMERIC_SUMMARY_OPTIONS } from '../../../constants/aggregationTypes';
import { VIEW_ACTIONS, VIEW_SETUP } from '../../../constants/buildMode';
import { USER } from '../../../constants/builtInDataTypes';
import { ChartAggregation, SUM } from '../../../constants/chartAggregations';
import { TWO_WEEK } from '../../../constants/collection';
import {
  BOARD,
  CALENDAR,
  CARDS,
  CHARTS,
  CHECKLIST,
  COLUMNS,
  EVENT_BASED_LAYOUTS,
  GANTT,
  MAP,
  PIVOT_TABLE,
  ROWS,
  SINGLE_RECORD,
  SPLIT,
  TIMELINE,
} from '../../../constants/collectionLayouts';
import { darkModeColors } from '../../../constants/darkModeColors';
import {
  BOOLEAN,
  DATE,
  DECIMAL,
  DURATION,
  INTEGER,
} from '../../../constants/dataTypes';
import { PAGE, VIEW } from '../../../constants/elements';
import { ASC } from '../../../constants/orderByDirections';
import { DATABASE } from '../../../constants/scopeTypes';
import { DataField } from '../../../models/DataTypeFields';
import { DataType } from '../../../models/DataTypes';
import { DepValue, Element, ElementPath } from '../../../models/Element';
import { Project } from '../../../models/Project';
import StateItem from '../../../models/StateItem';
import { GroupBy } from '../../../models/View';
import { ensureArray } from '../../../utils/arrays';
import { getDurationFromString } from '../../../utils/durations';
import useHasFeatureFlag, {
  CARD_SIZES,
  CARD_STYLES,
  RECORD_STYLES,
} from '../../../utils/hooks/useHasFeatureFlag';
import { useIsSubpage } from '../../../utils/hooks/useIsSubpage';
import useIsTable from '../../../utils/hooks/useIsTable';
import { getText } from '../../../utils/lang';
import { Page } from '../../../utils/pages';
import { getTypeOptionsOfTypeFromParent } from '../../../utils/renderedOptions';
import { withNullOption } from '../../../utils/settings';
import {
  BuildModeGroupByInput,
  DropdownButton,
} from '../BuildModeGroupByInput';
import BuildModeHeader from '../BuildModeHeader';
import BuildModeInput from '../BuildModeInput';
import BuildModeNavigation from '../BuildModeNavigation';
import BuildModeSection from '../BuildModeSection';
import EnableDragAndDropEditToggle from '../EnableDragAndDropEditToggle';
import BuildModePivotTableEditor from './BuildModePivotTableEditor';
import BuildModeViewDataSettings from './BuildModeViewDataSettings';
import BuildModeViewLayoutEditor from './BuildModeViewLayoutEditor';

interface BuildModeViewGeneralTabProps {
  dataType?: DataType;
  debouncedUpdateProperty: UpdatePropertyCallback;
  element: Page;
  elementPath: ElementPath;
  hideDataTypeInput?: boolean;
  hideSingleRecordOption?: boolean;
  isRecordView?: boolean;
  project: Project;
  section?: Element;
  sectionPropPath?: ElementPath;
  updateProperty: UpdatePropertyCallback;
}

const LANG_KEY = 'elements.VIEW';
const CALENDAR_LAYOUTS = [Views.MONTH, Views.WEEK, Views.WORK_WEEK, Views.DAY];
const SPLIT_VIEW_SIZES = [SM, MD, LG];
const MAP_TYPE_OPTIONS = [SATELLITE, TERRAIN, ROADMAP];
const DEFAULT_MAP_TYPE = ROADMAP;
const DEFAULT_SPLIT_VIEW_SIZE = MD;

const BuildModeViewGeneralTab = ({
  debouncedUpdateProperty,
  element,
  elementPath,
  hideDataTypeInput = true,
  hideSingleRecordOption = false,
  isRecordView = false,
  project,
  section,
  sectionPropPath = [],
  updateProperty,
}: BuildModeViewGeneralTabProps) => {
  const isCardSizesEnabled = useHasFeatureFlag(CARD_SIZES);
  const isRecordStylesEnabled = useHasFeatureFlag(RECORD_STYLES);
  const isCardStylesEnabled = useHasFeatureFlag(CARD_STYLES);

  const collectionElement = useMemo(
    () => (section ?? element) as Page | Element,
    [section, element],
  );

  const collectionElementPath = useMemo(
    () => (section ? sectionPropPath : elementPath),
    [section, sectionPropPath, elementPath],
  );

  const {
    calendarView,
    cardSize = LG,
    checklistField,
    cardStyle = CARD,
    dataList = {},
    dateEnd,
    dateStart,
    endTime,
    ganttDependency,
    groupBy,
    groupBySort = ASC,
    groupOptions,
    groups,
    hideEmptyGroups,
    layout,
    limitPerGroup,
    map = {},
    pivotTable,
    recordStyle,
    recordTitle,
    rowLink,
    sidebarSize,
    startTime,
    summary: { field: summaryField = null, type: summaryType = SUM } = {},
  } = collectionElement.props || {};

  const timeOptions = useMemo(() => {
    const defaultOption = { value: null, label: 'Default' };
    const options = Array.from({ length: 24 }, (_, hour) => {
      const display = getDurationFromString(String(hour))?.toFormat('hh:mm');

      return { value: hour, label: display };
    });

    return [defaultOption, ...options];
  }, []);

  const dataType = useMemo(() => {
    const typeName = get(dataList, 'dataType');

    return (
      (typeName && project.dataTypes.getByName(typeName)) ??
      project.dataTypes.getByName(USER)
    );
  }, [dataList, project.dataTypes]);

  const sectionHasDataType = useMemo(
    () => get(collectionElement, 'props.dataList.dataType', null),
    [collectionElement],
  );

  const stateItem = useMemo(
    () =>
      dataType &&
      new StateItem({
        id: `${collectionElement.id}${
          collectionElement.type === VIEW ? ':VIEW' : ''
        }`,
        path: collectionElement.type === VIEW ? '' : 'edges.node',
        source: DATABASE,
        dataType: dataType.name,
        display: dataType.display,
      }),
    [dataType, collectionElement.id, collectionElement.type],
  );

  const mapPositionOptions = useMemo(
    () =>
      withNullOption(
        getTypeOptionsOfTypeFromParent(project.dataTypes, stateItem, [
          DECIMAL,
          INTEGER,
        ]),
      ),
    [project, stateItem],
  );

  const summaryFieldOptions = useMemo(
    () =>
      withNullOption(
        getTypeOptionsOfTypeFromParent(project.dataTypes, stateItem, [
          DECIMAL,
          DURATION,
          INTEGER,
        ]),
      ),
    [project, stateItem],
  );

  const summaryTypeOptions = useMemo(
    () =>
      NUMERIC_SUMMARY_OPTIONS.map((aggregation) => ({
        value: aggregation,
        label: getText('elements.CHART.aggregation', aggregation),
      })),
    [],
  );

  const groupByGroups: GroupBy[] = useMemo(() => {
    if (groups) {
      if (groups.length > 0) {
        return groups;
      }

      return [{ id: 'new' }];
    }

    return [
      {
        id: 'legacy',
        field: groupBy,
        sort: groupBySort,
      },
    ];
  }, [groupBy, groupBySort, groups]);

  const isSubPage = useIsSubpage(element);

  const isTable = useIsTable(layout);
  const isSingleLayout = layout === SINGLE_RECORD;
  const canMultiGroup =
    isTable || layout === SPLIT || layout === ROWS || layout === CHECKLIST;
  const layoutIsGroupable = useMemo(
    () => layout !== CHARTS && layout !== PIVOT_TABLE,
    [layout],
  );
  const isCalendar = useMemo(() => layout === CALENDAR, [layout]);
  const isCalendarOrTimelineOrGanttLayout = useMemo(
    () => layout === CALENDAR || layout === TIMELINE || layout === GANTT,
    [layout],
  );
  const isAdvancedGroupableLayout = useMemo(
    () => !EVENT_BASED_LAYOUTS.includes(layout) && layout !== MAP,
    [layout],
  );
  const hasAdvancedGrouping = useMemo(
    () => layoutIsGroupable && isAdvancedGroupableLayout,
    [layoutIsGroupable, isAdvancedGroupableLayout],
  );

  const showRecordTitleEditor = useMemo(
    () => isCalendarOrTimelineOrGanttLayout || layout === MAP,
    [isCalendarOrTimelineOrGanttLayout, layout],
  );

  const dateOptions = useMemo(
    () =>
      withNullOption(
        getTypeOptionsOfTypeFromParent(project.dataTypes, stateItem, [DATE]),
      ).filter((option) => !option.options || option.options.length === 0),
    [project, stateItem],
  );

  const ganttDependencyOptions = useMemo(
    () =>
      dataType &&
      withNullOption(
        dataType.fields
          .filter(
            (field: DataField) =>
              (field.relationship || field.relatedField) &&
              field.type === dataType.name,
          )
          .map((field: DataField) => ({
            value: {
              ...stateItem,
              path:
                collectionElement.type === VIEW
                  ? field.name
                  : `edges.node.${field.name}`,
              display: field.display,
            },
            label: field.display,
          })),
      ),
    [dataType, stateItem, collectionElement],
  );

  const layoutOptions = useMemo(
    () =>
      [
        ...CALENDAR_LAYOUTS,
        ...(layout === TIMELINE || layout === GANTT ? [TWO_WEEK] : []),
      ].map((calendarLayout) => ({
        label: getText('elements.COLLECTION.calendar.views', calendarLayout),
        value: calendarLayout,
      })),
    [layout],
  );

  const enableDragAndDropEdit = useMemo(
    () => get(collectionElement, ['props', 'enableDragAndDropEdit'], true),
    [collectionElement],
  );

  const canEnableDragAndDropEdit = useMemo(
    () =>
      layout !== SINGLE_RECORD &&
      groupByGroups.length > 0 &&
      layoutIsGroupable &&
      hasAdvancedGrouping,
    [layout, groupByGroups, layoutIsGroupable, hasAdvancedGrouping],
  );

  const shouldRenderActionSection = useMemo(
    () =>
      isCalendarOrTimelineOrGanttLayout ||
      canEnableDragAndDropEdit ||
      (dataType && layout !== CHARTS),
    [
      isCalendarOrTimelineOrGanttLayout,
      canEnableDragAndDropEdit,
      dataType,
      layout,
    ],
  );

  const showRecordStyleEditor = useMemo(
    () =>
      isRecordStylesEnabled &&
      !isRecordView &&
      element?.type !== PAGE &&
      rowLink !== '#' &&
      !isSubPage,
    [isRecordView, element?.type, rowLink, isRecordStylesEnabled, isSubPage],
  );

  const updateGroupsProperty: UpdatePropertyCallback = useCallback(
    (path: ElementPath, value: any) => {
      if (ensureArray(groups).length === 0 && groupBy) {
        const key = last(path) as string;

        // This will convert the legacy groupBy to groups while also updating the required field
        return updateProperty(
          ['groups'],
          [
            {
              field: groupBy,
              id: shortid.generate(),
              sort: groupBySort,
              [key === 'groupBySort' ? 'sort' : key]: value,
            },
          ],
        );
      }

      const legacyPaths = [
        'groupBy',
        'groupBySort',
        'groupOptions',
        'hideEmptyGroups',
      ];

      if (!legacyPaths.includes(String(first(path)!))) {
        // This to forcefully save groups as an array since updateProperty(['groups', ...path], value) causes groups to be converted into object.
        // It will also convert any existing groups to array that are currently incorrectly stored as objects.
        if (first(path) === 0 && last(path) === 'field') {
          return updateProperty(
            ['groups'],
            [{ field: value, id: shortid.generate(), sort: ASC }],
          );
        }

        return updateProperty(['groups', ...path], value);
      }

      // This it to support legacy groupBy and groupBySort updates
      updateProperty(path, value);
    },
    [groups, updateProperty, groupBy, groupBySort],
  );

  const checklistFieldOptions = useMemo(
    () =>
      getTypeOptionsOfTypeFromParent(project.dataTypes, stateItem, [BOOLEAN]),
    [project.dataTypes, stateItem],
  );

  return (
    <>
      <BuildModeViewDataSettings
        element={collectionElement}
        hideDataTypeInput={hideDataTypeInput}
        project={project}
        updateProperty={updateProperty}
      />
      {sectionHasDataType && (
        <>
          <BuildModeViewLayoutEditor
            checklistFieldOptions={checklistFieldOptions}
            dataType={dataType}
            element={collectionElement}
            hideSingleRecordOption={hideSingleRecordOption}
            project={project}
            updateProperty={updateProperty}
          />
          {!isSingleLayout && layout !== CHARTS && (
            <BuildModeSection
              id={VIEW_SETUP}
              className="border-t p-2"
              sticky={true}
              title={getText('rightSidebar.editor.setup')}
            >
              <div className="mb-2 p-2 text-sm">
                {layout === SPLIT && (
                  <BuildModeInput
                    label={getText(
                      'elements.COLLECTION.split.columnSpan.label',
                    )}
                  >
                    <SwitchButton
                      className="h-8 rounded-lg"
                      onChange={(value) =>
                        updateProperty(['sidebarSize'], value)
                      }
                      options={SPLIT_VIEW_SIZES.map((size) => ({
                        label: getText('elements.SELECT_INPUT.sizes', size),
                        value: size,
                      }))}
                      value={sidebarSize || DEFAULT_SPLIT_VIEW_SIZE}
                    />
                  </BuildModeInput>
                )}
                {layout !== SINGLE_RECORD && (
                  <div className="space-y-4">
                    {layout === MAP && (
                      <>
                        <BuildModeInput
                          label={getText(
                            LANG_KEY,
                            'display.map.latitude.label',
                          )}
                        >
                          <SelectInput
                            shiftRight={true}
                            Button={DataTypeValue}
                            contained={true}
                            className="text-black"
                            value={map.latitude}
                            options={mapPositionOptions}
                            onChange={(value: any) =>
                              updateProperty(['map', 'latitude'], value)
                            }
                            placeholder={getText(
                              LANG_KEY,
                              'display.map.latitude.placeholder',
                            )}
                            searchable={true}
                          />
                        </BuildModeInput>
                        <BuildModeInput
                          label={getText(
                            LANG_KEY,
                            'display.map.longitude.label',
                          )}
                        >
                          <SelectInput
                            shiftRight={true}
                            Button={DataTypeValue}
                            contained={true}
                            className="text-black"
                            value={map.longitude}
                            options={mapPositionOptions}
                            onChange={(value: any) =>
                              updateProperty(['map', 'longitude'], value)
                            }
                            placeholder={getText(
                              LANG_KEY,
                              'display.map.longitude.placeholder',
                            )}
                            searchable={true}
                          />
                        </BuildModeInput>
                        <BuildModeInput
                          label={getText(LANG_KEY, 'display.map.mapView.label')}
                        >
                          <SwitchButton
                            className="h-8 rounded-lg"
                            onChange={(value) =>
                              updateProperty(['map', 'mapType'], value)
                            }
                            options={MAP_TYPE_OPTIONS.map((option) => ({
                              label: getText(
                                LANG_KEY,
                                'display.map.mapView.mapTypes',
                                option,
                              ),
                              value: option,
                            }))}
                            value={map.mapType || DEFAULT_MAP_TYPE}
                          />
                        </BuildModeInput>
                      </>
                    )}
                    {isCalendarOrTimelineOrGanttLayout && (
                      <>
                        <BuildModeInput
                          label={getText(LANG_KEY, 'display.dateStart.title')}
                        >
                          <SelectInput
                            shiftRight={true}
                            Button={DataTypeValue}
                            contained={true}
                            className="text-black"
                            value={dateStart}
                            options={dateOptions}
                            onChange={(value: any) =>
                              updateProperty(['dateStart'], value)
                            }
                            placeholder={getText(
                              LANG_KEY,
                              'display.dateStart.placeholder',
                            )}
                            searchable={true}
                          />
                        </BuildModeInput>
                        <BuildModeInput
                          label={getText(LANG_KEY, 'display.dateEnd.title')}
                        >
                          <SelectInput
                            shiftRight={true}
                            Button={DataTypeValue}
                            contained={true}
                            className="text-black"
                            value={dateEnd}
                            options={dateOptions}
                            onChange={(value: any) =>
                              updateProperty(['dateEnd'], value)
                            }
                            placeholder={getText(
                              LANG_KEY,
                              'display.dateEnd.placeholder',
                            )}
                            searchable={true}
                          />
                        </BuildModeInput>
                        {layout === GANTT && (
                          <BuildModeInput
                            label={getText(
                              LANG_KEY,
                              'display.ganttDependency.title',
                            )}
                          >
                            <SelectInput
                              shiftRight={true}
                              Button={DataTypeValue}
                              contained={true}
                              className="text-black"
                              value={ganttDependency}
                              options={ganttDependencyOptions}
                              onChange={(value: any) =>
                                updateProperty(['ganttDependency'], value)
                              }
                              placeholder={getText(
                                LANG_KEY,
                                'display.ganttDependency.placeholder',
                              )}
                              searchable={true}
                            />
                          </BuildModeInput>
                        )}
                        <BuildModeInput
                          label={getText(
                            LANG_KEY,
                            'display.calendarView.label',
                          )}
                        >
                          <SelectInput
                            shiftRight={true}
                            contained={true}
                            className="text-black"
                            value={calendarView || Views.MONTH}
                            options={layoutOptions}
                            onChange={(value: any) =>
                              updateProperty(['calendarView'], value)
                            }
                          />
                        </BuildModeInput>
                      </>
                    )}
                    {[CARDS, COLUMNS].includes(layout) && (
                      <>
                        {isCardSizesEnabled && (
                          <BuildModeInput
                            label={getText(LANG_KEY, 'display.cardSize')}
                          >
                            <SizeEditor
                              defaultSize={LG}
                              onChange={(value) =>
                                updateProperty(['cardSize'], value)
                              }
                              sizes={[SM, MD, LG, XL]}
                              value={cardSize}
                            />
                          </BuildModeInput>
                        )}
                        {isCardStylesEnabled && (
                          <BuildModeInput
                            inline={true}
                            label={getText(LANG_KEY, 'display.cardStyle.title')}
                          >
                            <SwitchButton
                              className="h-8 rounded-lg"
                              onChange={(value) =>
                                updateProperty(['cardStyle'], value)
                              }
                              options={cardStyles.map((style) => ({
                                label: getText(
                                  LANG_KEY,
                                  'display.cardStyle',
                                  style,
                                ),
                                value: style,
                              }))}
                              value={cardStyle}
                            />
                          </BuildModeInput>
                        )}
                      </>
                    )}
                    {isCalendar && (
                      <div className="flex space-x-2">
                        <BuildModeInput
                          label={getText(LANG_KEY, 'display.startTime.title')}
                        >
                          <SelectInput
                            placement="left-start"
                            contained={true}
                            className="text-black"
                            value={startTime}
                            options={timeOptions}
                            onChange={(value: any) =>
                              updateProperty(['startTime'], value)
                            }
                            placeholder={getText(
                              LANG_KEY,
                              'display.startTime.placeholder',
                            )}
                          />
                        </BuildModeInput>
                        <BuildModeInput
                          label={getText(LANG_KEY, 'display.endTime.title')}
                        >
                          <SelectInput
                            placement="left-start"
                            contained={true}
                            className="text-black"
                            value={endTime}
                            options={timeOptions}
                            onChange={(value: any) =>
                              updateProperty(['endTime'], value)
                            }
                            placeholder={getText(
                              LANG_KEY,
                              'display.endTime.placeholder',
                            )}
                          />
                        </BuildModeInput>
                      </div>
                    )}
                    {showRecordTitleEditor && (
                      <BuildModeInput
                        label={getText(LANG_KEY, 'display.recordTitle.label')}
                      >
                        <RecordTitleEditor
                          dataType={dataType}
                          value={recordTitle}
                          onChange={(value: string) =>
                            updateProperty(['recordTitle'], value)
                          }
                        />
                      </BuildModeInput>
                    )}
                    {layoutIsGroupable && (
                      <BuildModeGroupByInput
                        canMultiGroup={canMultiGroup}
                        dataType={dataType}
                        dataTypes={project.dataTypes}
                        groupBy={groupBy}
                        groupByGroups={groupByGroups}
                        groupBySort={groupBySort}
                        groupOptions={groupOptions}
                        groups={groups}
                        hasAdvancedGrouping={hasAdvancedGrouping}
                        hideEmptyGroups={hideEmptyGroups}
                        layout={layout}
                        stateItem={stateItem}
                        updateProperty={updateGroupsProperty}
                      />
                    )}
                    {layout === BOARD && (
                      <BuildModeInput
                        label={getText(LANG_KEY, 'display.summary.label')}
                      >
                        <div className="flex items-center space-x-2">
                          <SelectInput
                            Button={DataTypeValue}
                            className="w-full text-black"
                            contained={true}
                            onChange={(value: StateItem) =>
                              updateProperty(['summary', 'field'], value)
                            }
                            options={summaryFieldOptions}
                            placeholder={getText(
                              LANG_KEY,
                              'display.summary.placeholder',
                            )}
                            searchable={true}
                            shiftRight={true}
                            value={summaryField}
                          />
                          {summaryField && (
                            <Tooltip
                              content={
                                <span className={darkModeColors.text.primary}>
                                  {getText(LANG_KEY, 'pivotTable.summaryType')}
                                </span>
                              }
                              placement="top"
                              showArrow={false}
                              surface={DARK}
                            >
                              <Dropdown
                                Button={DropdownButton}
                                onChange={(aggregation) =>
                                  updateProperty(
                                    ['summary', 'type'],
                                    aggregation as ChartAggregation,
                                  )
                                }
                                options={summaryTypeOptions}
                                size={MD}
                                value={summaryType}
                                stopPropagation={true}
                              >
                                <IconCalculator size={16} />
                              </Dropdown>
                            </Tooltip>
                          )}
                        </div>
                      </BuildModeInput>
                    )}
                    {layout === PIVOT_TABLE && pivotTable && (
                      <BuildModePivotTableEditor
                        canMultiGroup={canMultiGroup}
                        dataType={dataType}
                        hasAdvancedGrouping={hasAdvancedGrouping}
                        hideEmptyGroups={hideEmptyGroups}
                        pivotTable={pivotTable}
                        project={project}
                        stateItem={stateItem}
                        updateProperty={updateProperty}
                      />
                    )}
                    {layout === BOARD && (
                      <BuildModeInput
                        label={getText(LANG_KEY, 'display.limitPerGroup.limit')}
                      >
                        <StringPropEditor
                          // @ts-expect-error TS(2322): Type '{ project: any; contained: boolean; onChange... Remove this comment to see the full error message
                          contained={true}
                          elementPath={collectionElementPath}
                          onChange={(newLimit: any) =>
                            updateProperty(['limitPerGroup'], newLimit)
                          }
                          placeholder={getText(
                            LANG_KEY,
                            'display.limitPerGroup.placeholder',
                          )}
                          project={project}
                          value={limitPerGroup}
                        />
                      </BuildModeInput>
                    )}
                    {layout === CHECKLIST && (
                      <BuildModeInput
                        label={getText(LANG_KEY, 'display.checklist.label')}
                      >
                        <SelectInput
                          Button={DataTypeValue}
                          className="w-full text-black"
                          contained={true}
                          onChange={(value: DepValue) =>
                            updateProperty(['checklistField'], value)
                          }
                          options={checklistFieldOptions}
                          placeholder={getText(
                            LANG_KEY,
                            'display.checklist.placeholder',
                          )}
                          searchable={true}
                          shiftRight={true}
                          value={checklistField}
                        />
                      </BuildModeInput>
                    )}
                  </div>
                )}
              </div>
            </BuildModeSection>
          )}
          <BuildModeHeader
            dataType={dataType}
            debouncedUpdateProperty={debouncedUpdateProperty}
            elementPath={collectionElementPath}
            elementType={VIEW}
            project={project}
            props={collectionElement.props}
            section={section}
            showBreadcrumbs={!section}
            updateProperty={updateProperty}
          />
          {layout !== PIVOT_TABLE && shouldRenderActionSection && (
            <BuildModeSection
              className="border-t p-2"
              id={VIEW_ACTIONS}
              sticky={true}
              title={getText('rightSidebar.editor.actions')}
            >
              <div className="mb-2 space-y-4 p-2 text-sm">
                {isCalendarOrTimelineOrGanttLayout && (
                  <EnableDragAndDropEditToggle
                    enableDragAndDropEdit={enableDragAndDropEdit}
                    label={getText(
                      LANG_KEY,
                      'display.calendarEnableEventDragAndDrop.label',
                    )}
                    updateProperty={updateProperty}
                  />
                )}
                {canEnableDragAndDropEdit && (
                  <EnableDragAndDropEditToggle
                    enableDragAndDropEdit={enableDragAndDropEdit}
                    label={getText(
                      LANG_KEY,
                      'display.groupLayoutEnableDragAndDrop',
                    )}
                    updateProperty={updateProperty}
                  />
                )}
                {dataType && layout !== CHARTS && (
                  <>
                    <ViewLinkEditor
                      label={
                        <div className="flex items-center space-x-2">
                          <IconClick size={14} />
                          <span>{getText(LANG_KEY, 'rowLink.onClick')}</span>
                        </div>
                      }
                      dataType={dataType}
                      onChange={(nextRowLink?: string) =>
                        updateProperty(['rowLink'], nextRowLink)
                      }
                      project={project}
                      value={rowLink}
                    />
                    {showRecordStyleEditor && (
                      <ViewRecordStyleEditor
                        onChange={(path, value) =>
                          updateProperty(['recordStyle', ...path], value)
                        }
                        value={recordStyle}
                      />
                    )}
                  </>
                )}
              </div>
            </BuildModeSection>
          )}
          {!isRecordView && element?.type !== PAGE && (
            <BuildModeNavigation
              element={collectionElement}
              project={project}
              updateProperty={updateProperty}
            />
          )}
        </>
      )}
    </>
  );
};

export default BuildModeViewGeneralTab;
