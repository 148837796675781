import { useMemo } from 'react';
import { get } from 'lodash';
import isNil from 'lodash/isNil';
import { Page } from '../pages';

export const useIsSubpage = (element: Element | Page) =>
  useMemo(() => {
    const elementProps = get(element, 'props', {});

    return 'parentPage' in elementProps && !isNil(elementProps.parentPage);
  }, [element]);
